'use strict';

import _ from 'shared/js/underscore';
import CustomerSections from './customerSections/customerSections';
import CustomerSectionsLockable from './customerSections/customerSectionsLockable';
import Alpine from 'shared/js/alpinejs';
import alpineAccessor from './customerSections/alpineAccessor';
import CustomerSectionsChannel from './customerSections/customerSectionsChannel';
import CustomerSectionsChannelLockable from './customerSections/customerSectionsChannelLockable';
import { getLogger, isDevMode } from 'shared/js/dev-mode';
import scenarios from './customerSections/customerSectionsTrigger';

const logger = getLogger();

const browserAdapter = (window._csBootstrap && window._csBootstrap.preferences)
    ? window._csBootstrap.preferences.browserAdapter
    : false;

const isWebApiLockAvailable = () => {
    return 'locks' in navigator && typeof navigator.locks.request === 'function';
};

alpineAccessor(Alpine);

let csc,
    cs;
if (isWebApiLockAvailable() && browserAdapter === 'lockable') {
    csc = new CustomerSectionsChannelLockable();
    cs = new CustomerSectionsLockable(csc);
} else {
    csc = new CustomerSectionsChannel();
    cs = new CustomerSections(csc);
}
_.delay(() => {
    cs.boot().then(() => {
        logger.log('CS Boot is triggered, registering triggers');
        // Register custom UX scenarios.
        scenarios.forEach((record) => {
            let { listenTo, callback } = record;
            if (listenTo && callback instanceof Function) {
                cs.observe(listenTo, callback);
            }
        });
        // Notify subscribers when CS is loaded and ready.
        cs.readyPromise.then(() => {
            const csLoadedEvent = new CustomEvent('alpine:customer-sections:loaded', {
                detail: {
                    cs: cs,
                    Alpine: Alpine
                }
            });
            document.dispatchEvent(csLoadedEvent);
        });
    });
}, 1);

// Allow invalidation of sections in runtime in dev mode.
if (isDevMode()) {
    window._csFetch = cs.fetch.bind(cs);
}


export default cs;
