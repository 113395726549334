'use strict';

import Provider from './gamifiera/eventProvider';
import cs from 'shared/js/customerSections';

document.addEventListener('integration:event-provider', function (event) {
    // Don't wait for CS data initialization via cs.readyPromise.then
    cs.getAwait('configuration', 'gmfIsActive').then((value) => {
        if (value) {
            event.detail.dispatcher.register(new Provider());
        }
    });
});
