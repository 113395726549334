'use strict';

import db from './localforage';
import {
    executeCallback,
    getCallback,
    getKeyPrefix,
    normalizeKey,
    getSerializerPromise,
    isStorageUsable, isStorageValid
} from './driver-utils';

// Config the session/localStorage backend, using options set in the config.
function _initStorage(instance, options) {
    const dbInfo = {
        ...(options),
        keyPrefix: getKeyPrefix(options, instance._defaultConfig),
    };

    // Main data storage.
    dbInfo.db = db.createInstance(options);
    // Cache expiration info mirror.
    dbInfo.dbCache = db.createInstance(options);

    if (!isStorageUsable(dbInfo.db)) {
        return Promise.reject();
    }

    instance._dbInfo = dbInfo;

    return getSerializerPromise(instance).then(serializer => {
        dbInfo.serializer = serializer;
    });
}

// Remove all keys from the datastore, effectively destroying all data in
// the app's key/value store!
function clear(instance, callback) {
    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix } = instance._dbInfo;

        for (let i = store.length - 1; i >= 0; i--) {
            const keyIndex = store.key(i) || '';

            if (keyIndex.indexOf(keyPrefix) === 0) {
                store.removeItem(keyIndex);
                storeCache.removeItem(keyIndex);
            }
        }
    });

    executeCallback(promise, callback);
    return promise;
}

// Retrieve an item from the store. Unlike the original async_storage
// library in Gaia, we don't modify return values at all. If a key's value
// is `undefined`, we pass that value to the callback function.
function getItem(instance, keyIndex, callback) {
    keyIndex = normalizeKey(keyIndex);

    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix, serializer } = instance._dbInfo;
        const resultCache = storeCache.getItem(keyPrefix + keyIndex); /* eslint-disable-line no-unused-vars */
        // TODO
        const result = store.getItem(keyPrefix + keyIndex);

        if (!result) {
            return result;
        }

        // If a result was found, parse it from the serialized
        // string into a JS object. If result isn't truthy, the key
        // is likely undefined and we'll pass it straight to the
        // callback.
        return serializer.deserialize(result);
    });

    executeCallback(promise, callback);
    return promise;
}

// Iterate over all items in the store.
function iterate(
    instance,
    iterator,
    callback
) {
    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix, serializer } = instance._dbInfo;
        const keyPrefixLength = keyPrefix.length;
        const lengthIndexes = store.length;

        // We use a dedicated iterator instead of the `i` constiable below
        // so other keys we fetch in session/localStorage aren't counted in
        // the `iterationNumber` argument passed to the `iterate()`
        // callback.
        //
        // See: github.com/mozilla/localForage/pull/435#discussion_r38061530
        let iterationNumber = 1;

        for (let i = 0; i < lengthIndexes; i++) {
            const keyCache = storeCache.key(i) || ''; /* eslint-disable-line no-unused-vars */
            // TODO
            const keyIndex = store.key(i) || '';
            if (keyIndex.indexOf(keyPrefix) !== 0) {
                continue; /* eslint-disable-line no-continue */
            }
            // TODO
            const cacheValue = storeCache.getItem(keyIndex); /* eslint-disable-line no-unused-vars */
            const storeValue = store.getItem(keyIndex);

            // If a result was found, parse it from the serialized
            // string into a JS object. If result isn't truthy, the
            // key is likely undefined and we'll pass it straight
            // to the iterator.
            const value = storeValue
                ? (serializer.deserialize(storeValue))
                : null;

            const iteratorResult = iterator(
                value,
                keyIndex.substring(keyPrefixLength),
                iterationNumber++,
            );

            if (iteratorResult !== void 0) { /* eslint-disable-line no-void */
                return iteratorResult;
            }
        }
    });

    executeCallback(promise, callback);
    return promise;
}

// Same as session/localStorage's key() method, except takes a callback.
function key(
    instance,
    keyIndex,
    callback
) {
    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix } = instance._dbInfo;
        let result;
        let resultCache; /* eslint-disable-line no-unused-vars */
        try {
            resultCache = storeCache.key(keyIndex) || null; /* eslint-disable-line no-unused-vars */

            // TODO
            result = store.key(keyIndex) || null;
        } catch (error) {
            result = null;
        }

        if (!result) {
            return result;
        }

        // Remove the prefix from the key, if a key is found.
        return result.substring(keyPrefix.length);
    });

    executeCallback(promise, callback);
    return promise;
}

function keys(
    instance,
    callback
) {
    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix } = instance._dbInfo;
        const lengthIndexes = store.length;
        const keysIndexes = [];

        for (let i = 0; i < lengthIndexes; i++) {
            const cacheKey = storeCache.key(i) || ''; /* eslint-disable-line no-unused-vars */
            // TODO
            const itemKey = store.key(i) || '';
            if (itemKey.indexOf(keyPrefix) === 0) {
                keysIndexes.push(itemKey.substring(keyPrefix.length));
            }
        }

        return keysIndexes;
    });

    executeCallback(promise, callback);
    return promise;
}

// Supply the number of keys in the datastore to the callback function.
function length(
    instance,
    callback
) {
    const promise = instance.keys().then(keysIndexes => keysIndexes.length);

    executeCallback(promise, callback);
    return promise;
}

// Remove an item from the store, nice and simple.
function removeItem(
    instance,
    keyIndex,
    callback
) {
    keyIndex = normalizeKey(keyIndex);

    const promise = instance.ready().then(() => {
        const { db: store, dbCache: storeCache, keyPrefix } = instance._dbInfo;
        storeCache.removeItem(keyPrefix + keyIndex);
        // TODO
        store.removeItem(keyPrefix + keyIndex);
    });

    executeCallback(promise, callback);
    return promise;
}

// Set a key's value and run an optional callback once the value is set.
// Unlike Gaia's implementation, the callback function is passed the value,
// in case you want to operate on that value only after you're sure it
// saved, or something like that.
function setItem(
    instance,
    keyIndex,
    value,
    callback
) {
    keyIndex = normalizeKey(keyIndex);

    const promise = instance.ready().then(() => {
    // Convert undefined values to null.
    // https://github.com/mozilla/localForage/pull/42
        if (value === undefined) {
            value = null;
        }

        // Save the original value to pass to the callback.
        const originalValue = value;

        const { db: store, dbCache: storeCache, keyPrefix, serializer } = instance._dbInfo;
        return new Promise((resolve, reject) => {
            serializer.serialize(value, (val, error) => {
                if (error) {
                    reject(error);
                    return;
                }

                try {
                    // TODO
                    storeCache.setItem(keyPrefix + keyIndex, val);
                    store.setItem(keyPrefix + keyIndex, val);
                    resolve(originalValue);
                } catch (exception) {
                    // storage capacity exceeded.
                    // TODO: Make this a specific error/event.
                    if (
                        exception.name === 'QuotaExceededError' ||
                        exception.name === 'NS_ERROR_DOM_QUOTA_REACHED'
                    ) {
                        reject(exception);
                    }
                    reject(exception);
                }
            });
        });
    });

    executeCallback(promise, callback);
    return promise;
}

function dropInstance(
    instance,
    dbInstanceOptions,
    callback
) {
    callback = getCallback.apply(instance, arguments);

    const options =
        (typeof dbInstanceOptions !== 'function' && dbInstanceOptions) || {};
    if (!options.name) {
        const currentConfig = instance.config();
        options.name = options.name || currentConfig.name;
        options.storeName = options.storeName || currentConfig.storeName;
    }

    let promise;
    if (!options.name) {
        promise = Promise.reject('Invalid arguments');
    } else {
        try {
            const keyPrefix = !options.storeName
                ? `${options.name}/`
                : getKeyPrefix(options, instance._defaultConfig);

            const { db: store, dbCache: storeCache } = instance._dbInfo;
            for (let i = store.length - 1; i >= 0; i--) {
                const keyCache = storeCache.key(i) || ''; /* eslint-disable-line no-unused-vars */
                // TODO
                const keyIndex = store.key(i) || '';

                if (keyIndex.indexOf(keyPrefix) === 0) {
                    // TODO
                    storeCache.removeItem(keyIndex);
                    store.removeItem(keyIndex);
                }
            }
            promise = Promise.resolve();
        } catch (e) {
            promise = Promise.reject(e);
        }
    }

    executeCallback(promise, callback);
    return promise;
}

const syncDataStorageWrapper = {
    _driver: 'syncDataStorageWrapper',
    _initStorage,
    _support: isStorageValid(),
    iterate,
    getItem,
    setItem,
    removeItem,
    clear,
    length,
    key,
    keys,
    dropInstance
};

export default syncDataStorageWrapper;
