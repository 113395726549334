'use strict';

import _ from 'shared/js/underscore';
import db from 'shared/js/sync-data';
import CustomerMetaSections from './customerMetaSections';
import { addQueryToUrl, getControllerUrl } from 'shared/js/url';
import { isSectionInvalidationRequired } from './invalidationStateVerifier';
import { getDbName, getTableName } from './dbNameResolver';
import { extractMetaDataFields } from './extractMetaDataFields';
import { traverseToSetItems } from './traverseToSetItems';
import { transformXmlResponseToJson } from './transformXmlResponseToJson';
import { getLogger } from 'shared/js/dev-mode';
import { notify, observe } from './customerSectionObservable';
import { resolveOverride } from './resolveOverrides';
import { getCsrfName, getCsrfValue } from '../csrfToken';

const logger = getLogger();

const drivers = [
    // Do not use sessionStorage since it's not synchronized across tab, it serves different purpose
    'syncDataStorageWrapper',
    db.INDEXEDDB,
    db.WEBSQL,
    db.LOCALSTORAGE
];

const maxAwaitQueueCycleNumber = (window._csBootstrap && window._csBootstrap.preferences)
    ? window._csBootstrap.preferences.maxAwaitQueueCycleNumber
    : 0;
const tsUpdateRequestTtl = (window._csBootstrap && window._csBootstrap.preferences)
    ? window._csBootstrap.preferences.tsUpdateRequestTtl
    : 15000; // 15 seconds
const delayUpdateRequest = (window._csBootstrap && window._csBootstrap.preferences)
    ? window._csBootstrap.preferences.delayUpdateRequest
    : 3; // 3 seconds | prev. 10s
const throttlePingRequest = (window._csBootstrap && window._csBootstrap.preferences)
    ? window._csBootstrap.preferences.throttlePingRequest
    : 300000; // 5 minutes in ms

const capitalizeFirstLetter = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

const decreaseStack = (instance, id, stackPrefix) => {
    const stackId = `${stackPrefix}Stack`;
    const index = instance[stackId].indexOf(id);
    if (index > -1) {
        instance[stackId].splice(index, 1);
    }
    if (instance[stackId].length === 0) {
        instance[`${stackPrefix}PromiseResolve`]();
    }
    logger.debug(`Tasks stack decreased by ${stackId}, pending: ${instance[stackId].length}`);
};

const fetchPing = _.throttle((callback) => {
    fetch(getControllerUrl('Customer-Ping'), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(callback());
}, throttlePingRequest);

class CustomerSections {
    sections;
    dbs;
    metaDb;
    readyPromise;
    initReadyPromise;
    storageCreated;
    channel;
    changelog;
    sectionsTriggerObservables; // Listing of sections that are pending explicit invalidation.
    notifyBeforeFetch; // Flag that will send message via channel that specific sections have to be invalidated.

    constructor(channel) {
        let self = this;

        this.instanceId = `${Math.random().toString(36).slice(2, 7)}-${(new Date()).getTime()}`;
        this.debug('Constructor start');

        const metaDbTable = getTableName('meta-db');
        this.channel = channel;
        this.changelog = {};
        this.sections = {};
        this.dbs = {};
        this.sectionsTriggerObservables = [];
        this.storageCreated = false;
        this.initReadyPromiseResolve = () => {};
        this.readyPromiseResolve = () => {};
        this.notifyBeforeFetch = true;
        this.initReadyStack = [metaDbTable];
        this.readyStack = [`${metaDbTable}-read`];
        // Declare promises for two steps:
        // - when customer sections storage are setup (but may not have yet data).
        // - when customer sections storage are setup & has data (in case of invalidation -
        //   data is already invalidated/re-fetched).
        this.initReadyPromise = Promise.all([
            db.ready(),
            new Promise((resolve) => {
                self.debug(`InitReady Promise ping. Stack ${self.initReadyStack.length}`);
                self.initReadyPromiseResolve = resolve;
                self.initReadyStack.length === 0 && resolve();
            })
        ]);
        this.readyPromise = Promise.all([
            this.initReadyPromise,
            new Promise((resolve) => {
                self.debug(`Ready Promise ping. Stack ${self.readyStack.length}`);
                self.readyPromiseResolve = resolve;
                self.readyStack.length === 0 && resolve();
            })
        ]);

        this.sections = CustomerMetaSections.get('sections', {});
        this.debug(`Sections listing ${JSON.stringify(this.sections)}`);
        this.setUpChannel();

        db.setDriver(drivers);
        const dbName = getDbName();
        this.metaDb = db.createInstance({
            driver: drivers,
            name: dbName,
            storeName: metaDbTable,
            description: 'Meta data table'
        });

        // Dynamic binding in order to decouple complex logic.
        const csNotify = notify('dbs').bind(this);
        const csObserve = observe('dbs', this.initReadyPromise).bind(this);

        this.notify = function (sectionId) {
            this.debug(`Update notification for section ID ${sectionId}`);
            csNotify(sectionId);
        };
        this.observe = function (reference, subscriber) {
            this.debug(`Observer registered for ${reference}`);
            const [section, key] = reference.split(':');
            csObserve(section, key, subscriber);
        };

        this.debug('Constructor done');
    }

    debug(stmt) {
    // For easier navigations - all data updates & booting actions starts with message prefix:
    // "CS [verb] ..."
        logger.debug(`[${(new Date().toLocaleString())} @ CS:${this.instanceId}] ${stmt}`);
    }

    setUpChannel() {
        let self = this;
        // Listen on event when specific tab updates CS sections - other tabs should trigger observables to refresh UI.
        this.channel.registerListenerCallback('sectionsTriggerObservable', (msg) => {
            self.debug(`Channel "sectionsTriggerObservable" is triggered by message ${JSON.stringify(msg)}`);
            // Resolve sections IDs for value-change notification.
            const data = msg.value;
            if (data && _.isArray(data.value)) {
                self.sectionsTriggerObservables = _.uniq(self.sectionsTriggerObservables.concat(data.value));
            }
            if (data.immediate) {
                self.notifyPendingSectionsUpdate();
            }
        });
        // Listen for other instances of CS through channel requesting to update CS data.
        this.channel.registerListenerCallback('sectionsRequestUpdate', (msg) => {
            self.debug(`Channel "sectionsRequestUpdate" update with message ${JSON.stringify(msg)}`);
            // In case of receiving such a message - send a response to confirm another instance
            // request if current instance didn't start it.
            const data = msg.value;
            // If current received CS instance has new cookie in its changelog - it means
            // that it already started data update process.
            if (data.value && self.changelog[data.value]) {
                self.debug(`CS disallows update by another requesting CS instance, replacing instance ID "${data.instanceId}" by current CS ID`);
                // That will set a lock on request to update CS data by requesting instance,
                // because current instance is already handling it.
                // TODO Check if could be or not a case with setting shared but not further
                // executing sync.
                self.channel.set('sectionsRequestUpdate', {
                    // Replace the instanceId to the one which is handling data update.
                    reference: self.instanceId,
                    cookie: data.cookie,
                    value: data.value,
                    ts: data.ts
                });
            } else {
                self.debug(`CS allows to make data update by requesting instance ${data.instanceId}`);
                self.notifyPendingSectionsUpdate();
            }
        });

        // Set on-resoling updates to set shared resource-locking flags.
        this.initReadyPromise.then(() => {
            self.channel.set('sectionsStorageCreated', true);
            self.debug('sectionsStorageCreated = true on initReadyPromise');
        });
        this.readyPromise.then(() => {
            self.channel.register(); // Do not set sectionsDataLoaded flag
            self.debug('Channel is registered on readyPromise');
        });

        const cookieChangeListener = _.debounce(self.versionChangeHandler.bind(self), delayUpdateRequest * 3);
        // Register observer for CS versions cookie.
        CustomerMetaSections.addCallback(
            'syncDataStorageWrapper',
            (csm, cookeName, cookieValue) => {
                _.delay(() => {
                    cookieChangeListener(csm, cookeName, cookieValue);
                }, Math.random() * 2, arguments);
            }
        );
    }

    notifyPendingSectionsUpdate() {
        this.debug(`CS triggers observables for pending-to-notify sections ${this.sectionsTriggerObservables.join(',')}`);
        _.each(this.sectionsTriggerObservables, (sectionId) => {
            this.notify(sectionId);
        });
        this.sectionsTriggerObservables = [];
    }

    versionChangeHandler(csm, cookie, value) {
        let self = this;
        self.debug(`CS received update '${cookie}' = '${value}'`);
        if (!cookie || !value) {
            self.debug('CS exits version change handler: no meta cookie name or value is supplied');
            return;
        }

        // If current instance is aware of the being attempted to update cookie version
        // then skip further processing - that cookie update is currently being processed
        // or is process already.
        if (value && self.changelog.hasOwnProperty(value)) {
            self.debug(`CS already has ${value} in changelog`);
            return;
        } // if value = undefined - then it needs to be handled (updated).

        // Update internal sections meta info with new from cookie (runtime storage, in-browser memory).
        self.sections = CustomerMetaSections.get('sections', {});

        // Check presence of the lock on data update request.
        const tsNew = new Date().getTime();
        let updateRequestRecord = self.channel.get('sectionsRequestUpdate');
        if (updateRequestRecord
            && self.channel.get('sectionsRequestUpdate').ts + tsUpdateRequestTtl <= tsNew) {
            self.notifyPendingSectionsUpdate();
            updateRequestRecord = null;
        }

        // If current cookie version is currently in process of data fetching - skip...
        // - ongoing async update of in-browser storage AFTER fetch call
        if (!self.channel.get('sectionsUpdate')
            // - ongoing fetch request without actual data update in-browser yet
            && !self.channel.get('sectionsDataLoaded')
            // - current lock that will be unset only after triggering fetch request
            && !updateRequestRecord
        ) {
            delete window.__csDelayTimeout;
            window.__csDelayCycleCounter = 0;

            self.debug(`CS make requests to other CS instances for a lock acquiring for '${value}'`);
            // ... otherwise - request processing a cookie update with awaiting period.
            // Value identifies the instance for acquiring a lock.
            self.channel.lock('sectionsRequestUpdate', {
                instanceId: this.instanceId,
                cookie: cookie,
                value: value,
                ts: new Date().getTime()
            });
            _.delay(() => {
                let lockRecord = self.channel.get('sectionsRequestUpdate');
                self.debug(`CS's request for a lock expired, current lock's record state: ${JSON.stringify(lockRecord)}`);
                // Wait threshold and check if no other CS instances has already started
                // processing and didn't replace the instanceId (to indicate that
                // some instance is already handling data update).
                if (lockRecord && lockRecord.instanceId === self.instanceId) {
                    self.debug('CS instance starts to handle the data update request');
                    self.changelog[value] = true;
                    try {
                        self.boot().finally(() => {
                            self.debug('CS started a data update boot, releasing lock');
                            self.channel.unlock('sectionsRequestUpdate', false);
                        });
                    } catch (exception) {
                        self.debug(`CS got an error on data update boot: ${exception.message}`);
                        logger.error(exception);
                        self.channel.unlock('sectionsRequestUpdate', false);
                    }
                } else {
                    self.debug('CS does not proceed with data update, another CS instance is handling that');
                    self.notifyPendingSectionsUpdate();
                }
            }, delayUpdateRequest);
            self.debug('CS requested data update lock, awaiting');
        } else {
            self.debug('CS sees that some another CS instance already handling data update');
            self.debug('CS queues pending check if currently handling CS request will finish it');
            // Increasing delay.
            window.__csDelayTimeout = window.__csDelayTimeout ? (window.__csDelayTimeout + 10) : Math.floor((Math.random() * 100) + 1);
            window.__csDelayCycleCounter = window.__csDelayCycleCounter ? window.__csDelayCycleCounter + 1 : 1;

            if (maxAwaitQueueCycleNumber && window.__csDelayCycleCounter > maxAwaitQueueCycleNumber) {
                self.debug('CS reached max queue awaiting cycles, exiting');
                self.notifyPendingSectionsUpdate();
            } else {
                _.delay(self.versionChangeHandler.bind(self, csm, cookie, value), window.__csDelayTimeout);
            }
        }
    }

    async getAwait(section, path, defaults) {
        let value;
        // Translations and assets cannot be overridden because of key-value pairs storing.
        if (['translations', 'assets', 'menu'].indexOf(section) === -1) {
            // The next fragment allows to build logic for resolving FPC cacheable variables
            // without waiting for initialization of CS in customer's browser.
            value = resolveOverride(section, path, defaults);
            if (value !== defaults) {
                return value;
            }
        }
        return this.readyPromise.then(async () => {
            if (!this.dbs.hasOwnProperty(section)) {
                // Silently exit.
                return defaults;
            }
            value = await this.dbs[section].getItem(path);
            return value === undefined ? defaults : value;
        });
    }

    async getAll(section) {
        this.debug(`Call for a full section ${section}`);
        let result = {};
        if (!this.dbs.hasOwnProperty(section)) {
            // Silently exit.
            return result;
        }
        const keys = await this.dbs[section].keys();
        if (keys && keys.length) {
            return await (this.dbs[section].getItems(keys).then((data) => {
                if (['translations', 'assets', 'menu'].indexOf(section) === -1) {
                    _.each(data, (value, key) => {
                        let override = resolveOverride(section, key, undefined, false);
                        if (override !== undefined) {
                            data[key] = override;
                        }
                    });
                }
                return data;
            }));
        }
        return new Promise((resolve) => resolve({}));
    }

    createStorages() {
        const self = this;
        const dbName = getDbName();

        if (_.isEmpty(this.dbs)) {
            this.debug('CS instance does not have storage yet, started theirs creation');
            db.ready().then(() => {
                _.each(this.sections, (section, id) => {
                    if (!self.dbs[id]) {
                        const tableName = getTableName(id);
                        self.initReadyStack.push(tableName);
                        self.initReadyStack.push(`${tableName}_v`);
                        let instance = db.createInstance({
                            driver: drivers,
                            name: dbName,
                            storeName: tableName,
                            description: section.description || `Storage for ${id}`
                        });
                        self.dbs[id] = instance;
                        // Attempt to resolve already present versions.
                        self.metaDb.getItem(`${tableName}_v`).then((value) => {
                            if (!value) {
                                // Otherwise set zero version that will trigger data-fetch.
                                self.metaDb.setItem(`${tableName}_v`, 0).then(() => decreaseStack(self, `${tableName}_v`, 'initReady'));
                                self.debug(`CS has no version for ${tableName}, writing to meta-table "0" version`);
                            } else {
                                decreaseStack(self, `${tableName}_v`, 'initReady');
                            }
                        });
                        instance.ready(() => {
                            decreaseStack(self, tableName, 'initReady');
                        });
                    }
                });
                // Only now decrease the stack by metaDb.
                decreaseStack(self, getTableName('meta-db'), 'initReady');
                self.debug('CS has queued creation of all storages');
            });
        } else {
            this.debug('CS instance has storages already');
            try {
                this.metaDb.getItems();
            } catch (exception) {
                this.debug('ERROR: probably storage was removed');
                this.storageCreated = false;
                this.createStorages();
            }
        }
    }

    updateStorage(data) {
        let self = this;
        self.debug('CS starts updating of cache storage');
        this.createStorages();

        let updateResolve = {
            readyPromiseResolve: () => {
            },
            readyStack: ['init']
        };
        let updatePromise = new Promise(resolve => {
            updateResolve.readyPromiseResolve = resolve;
            updateResolve.readyStack.length === 0 && updateResolve.readyPromiseResolve();
        });

        const dataItems = _.get(data, ['data', 'items']);
        _.each(dataItems, (section, id) => {
            const stackTask = `${id}-update-data`;
            updateResolve.readyStack.push(stackTask);
        });
        self.debug(`CS identified all storages needed to update: ${updateResolve.readyStack.join(',')}`);

        _.each(dataItems, (section, id) => {
            const tableName = getTableName(id);
            const mode = section.mode || 'replace';
            const stackTask = `${id}-update-data`;
            delete self.sections[id].invalidationNeeded;
            self.debug(`CS queued data update for storage '${id}'`);
            if (mode === 'replace') {
                return self.dbs[id].clear().then(() => {
                    self.dbs[id].setItems(traverseToSetItems(section.items)).then(() => {
                        self.metaDb.setItem(`${tableName}_v`, section.version).then(() => {
                            decreaseStack(updateResolve, stackTask, 'ready');

                            // A way of notifying the current channel instance about section data update (ie within
                            // the current tab).
                            if (Array.isArray(self.channel.callbacksListener.crossTabUpdateNotification)) {
                                self.channel.callbacksListener.crossTabUpdateNotification.forEach((callback) => {
                                    callback({
                                        value: id,
                                        flag: 'crossTabUpdateNotification',
                                        action: 'lock'
                                    });
                                });
                            }

                            // The notification could be (if would be possible) be more selective.
                            self.notify(id);
                        });
                    });
                });
            }
            return self.dbs[id].setItems(traverseToSetItems(section.items)).then(() => {
                // The notification could be (if would be possible) be more selective.
                self.notify(id);
                self.metaDb.setItem(`${tableName}_v`, section.version).then(() => {
                    decreaseStack(updateResolve, stackTask, 'ready');
                    self.channel.notify('sectionsTriggerObservable', {
                        // Replace the instanceId to the one which is handling data update.
                        reference: self.instanceId,
                        value: [id],
                        ts: new Date().getTime(),
                        immediate: true
                    });
                });
            });
        });
        decreaseStack(updateResolve, 'init', 'ready');

        return updatePromise;
    }

    async fetch(forceInvalidationIds) {
        let self = this;
        let sectionsToInvalidate = forceInvalidationIds || [];

        const keys = _.uniq(_.reduce(Object.keys(this.sections) || {}, (memo, id) => {
            return memo.concat(memo, [`${getTableName(id)}_v`]);
        }, []));
        self.debug(`CS checks sections to invalidate among all: ${keys.join(', ')}`);

        let fetchKeys = _.uniq(keys);
        if (fetchKeys.length) {
            self.metaDb.getItems().then(function (metaData) {
                _.each(self.sections, (section, id) => {
                    if (isSectionInvalidationRequired(section, extractMetaDataFields(id, metaData))) {
                        sectionsToInvalidate.push(id);
                        self.debug(`CS section ${id} is marked for invalidation`);
                    }
                });

                if (self.notifyBeforeFetch) {
                    // Notify via channel which sections need to trigger observables.
                    self.channel.notify('sectionsTriggerObservable', {
                        // Replace the instanceId to the one which is handling data update.
                        reference: self.instanceId,
                        value: sectionsToInvalidate,
                        ts: new Date().getTime(),
                        immediate: false
                    });
                }

                const jsonBasedSections = _.filter(sectionsToInvalidate, (id) => {
                    return self.sections[id].render === 'json' || !self.sections[id].render;
                });
                const xmlBasedSection = _.filter(sectionsToInvalidate, (id) => {
                    return self.sections[id].render === 'xml';
                });

                if (jsonBasedSections.length > 0) {
                    self.readyStack.push('json-data-ready');
                    self.debug(`CS requests update for JSON based sections: ${jsonBasedSections.join(',')}`);
                    fetch(addQueryToUrl(getControllerUrl('Customer-Sections'), {
                        sections: sectionsToInvalidate.join(','),
                        [getCsrfName()]: getCsrfValue()
                    }), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((onFulfilled, onRejected) => { /* eslint-disable-line no-unused-vars */
                        self.debug(`CS got section response with status: ${onFulfilled.status}`);
                        if (onFulfilled.ok) {
                            onFulfilled.json().then(
                                data => data
                                    && data.data
                                    && self.channel.transaction(
                                        'sectionsUpdateJson',
                                        self.updateStorage.bind(self, data.data),
                                        null,
                                        false,
                                        false
                                    ).then(() => decreaseStack(self, 'json-data-ready', 'ready')));
                        }
                    });
                }

                _.each(xmlBasedSection, (sectionId) => {
                    self.readyStack.push('xml-data-ready');
                    self.debug(`CS requests update for XML based sections: ${xmlBasedSection.join(',')}`);
                    fetch(addQueryToUrl(getControllerUrl(`Customer-Section${capitalizeFirstLetter(sectionId)}`), { [getCsrfName()]: getCsrfValue() }), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'text/html'
                        }
                    }).then((onFulfilled, onRejected) => { /* eslint-disable-line no-unused-vars */
                        self.debug(`CS got section response with status: ${onFulfilled.status}`);
                        if (onFulfilled.ok) {
                            onFulfilled.text().then(
                                data => data
                                    && self.channel.transaction(
                                        'sectionsUpdateXml',
                                        self.updateStorage.bind(self, transformXmlResponseToJson(data, sectionId)),
                                        null,
                                        false,
                                        false
                                    ).then(() => decreaseStack(self, 'xml-data-ready', 'ready')));
                        }
                    });
                });

                const stackTask = `${self.metaDb._config.storeName}-read`;
                decreaseStack(self, stackTask, 'ready');
            });
        } else {
            self.debug('No CS sections for invalidation, session has expired, making ping');
            fetchPing(() => self.debug('CS ping is done'));
        }
    }

    async boot(skipFetchFlag) {
        skipFetchFlag = skipFetchFlag || false;
        let self = this;
        // It's important to call createStorages during booting, since new releases
        // can come with new sections, that needs to be created on client-end.
        this.createStorages();
        self.debug('CS started a data update boot');

        this.initReadyPromise.then(() => {
            // Avoid case of locking update process due to not fully finished previous update process.
            self.channel.set('sectionsRequestUpdate', false);

            self.debug('CS data update boot got initReadyPromise, proceeding with data update');
            // Configure observables.
            _.each(this.dbs, dbInstance => {
                dbInstance.configObservables({
                    crossTabNotification: true,
                    crossTabChangeDetection: true
                });
            });
            this.storageCreated = true;

            if (!skipFetchFlag) {
                self.debug('CS starts a transaction "sectionsDataLoaded" for data fetch');
                this.channel.transaction(
                    'sectionsDataLoaded',
                    this.fetch.bind(this),
                    () => {
                        const stackTask = `${self.metaDb._config.storeName}-read`;
                        decreaseStack(self, stackTask, 'ready');
                    },
                    false,
                    false
                );
            }
        });

        return this.readyPromise;
    }
}

export default CustomerSections;
