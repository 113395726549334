'use strict';

import driver from './sync-data/driver';
import db from './sync-data/localforage';
import { extendPrototype as setItems } from './sync-data/localforage-set-items';
import { extendPrototype as getItems } from './sync-data/localforage-get-items';
import { extendPrototype as removeItems } from './sync-data/localforage-remove-items';
import { extendPrototype as observable } from './sync-data/localforage-observable';
import { addFind } from './sync-data/localforage-find';
import { getLogger } from './dev-mode';

// TODO add Rx5 lib @see https://codepen.io/thgreasi/pen/wGLWmv
// OR webpack polyfill

setItems(db);
getItems(db);
removeItems(db);
observable(db);
addFind(db);

db.defineDriver(driver).catch((err) => {
    window.MessagesMgr.error('Communication failure');
    getLogger.error(err);
});

// db.newObservable.factory = function (subscribeFn) {
//     return Rx.Observable.create(subscribeFn);
// };

window._db = db;

export default db;
