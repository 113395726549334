'use strict';

import CustomerSections from './customerSections';
import CustomerMetaSections from './customerMetaSections';
import _ from 'shared/js/underscore';

/**
 * An adapter for customer sections data provider with support of Web Lock API.
 */
class CustomerSectionsLockable extends CustomerSections {
    constructor(channel) {
        super(channel);
        this.notifyBeforeFetch = false;
    }

    setUpChannel() {
        let self = this;
        const notifyPendingSectionsUpdateThrottled = _.throttle(self.notifyPendingSectionsUpdate, 1000, {
            trailing: false,
            leading: true
        });

        // Listen on event when specific tab updates CS sections - other tabs should trigger observables to refresh UI.
        this.channel.registerListenerCallback('sectionsTriggerObservable', (msg) => {
            self.debug(`Channel "sectionsTriggerObservable" is triggered by message ${JSON.stringify(msg)}`);
            // Resolve sections IDs for value-change notification.
            const data = msg.value;
            if (data && _.isArray(data.value)) {
                self.sectionsTriggerObservables = _.uniq(self.sectionsTriggerObservables.concat(data.value));
            }

            notifyPendingSectionsUpdateThrottled.cancel();

            if (data.immediate) {
                self.notifyPendingSectionsUpdate();
            } else {
                notifyPendingSectionsUpdateThrottled();
            }
        });

        // Lock "sectionsRequestUpdate" is not used anymore with Web Lock API.

        // Set on-resoling updates to set shared resource-locking flags.
        this.initReadyPromise.then(() => {
            self.channel.set('sectionsStorageCreated', true);
            self.debug('sectionsStorageCreated = true on initReadyPromise');
        });
        this.readyPromise.then(() => {
            self.channel.register(); // Do not set sectionsDataLoaded flag
            self.debug('Channel is registered on readyPromise');
        });

        // Register observer for CS versions cookie.
        CustomerMetaSections.addCallback(
            'syncDataStorageWrapper',
            _.debounce(self.versionChangeHandler.bind(self), Math.random() * 3)
        );
    }

    versionChangeHandler(csm, cookie, value) {
        this.debug(`CS received update '${cookie}' = '${value}'`);
        if (!cookie || !value) {
            this.debug('CS exits version change handler: no meta cookie name or value is supplied');
            return;
        }

        // If current instance is aware of the being attempted to update cookie version
        // then skip further processing - that cookie update is currently being processed
        // or is process already.
        if (value && this.changelog.hasOwnProperty(value)) {
            this.debug(`CS already has ${value} in changelog`);
            return;
        } // if value = undefined - then it needs to be handled (updated).

        // Update internal sections meta info with new from cookie (runtime storage, in-browser memory).
        this.sections = CustomerMetaSections.get('sections', {});

        // Check presence of the lock on data update request.

        this.debug('CS instance starts to handle the data update request');
        this.changelog[value] = true;
        try {
            this.boot().finally(() => {
                this.debug('CS started a data update boot, releasing lock');
                this.channel.unlock('sectionsRequestUpdate', false);
            });
        } catch (exception) {
            this.debug(`CS got an error on data update boot: ${exception.message}`);
            this.error(exception);
        }

        // notify
        this.debug('CS does not proceed with data update, another CS instance is handling that');
        this.notifyPendingSectionsUpdate();
    }
}

export default CustomerSectionsLockable;
