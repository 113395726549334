'use strict';

/* eslint-disable no-use-before-define */

import { addQueryToUrl, getControllerUrl } from 'shared/js/url';
import messagesMgr from 'shared/js/messagesMgr';
import _ from 'shared/js/underscore';

export default function (Alpine) { // { value, modifiers, expression }, { evaluateLater, effect }
    Alpine.directive('wishlist', (el, directive, expr) => {
        if (!directive.value) handleRoot(el, Alpine, expr); // eslint-ignore-line
        else if (directive.value === 'togglestate') handleToggleState(el, Alpine, directive.expression, expr); // eslint-ignore-line
    });

    function handleRoot(el, Alpine) { /* eslint-disable-line no-shadow */
        Alpine.bind(el, {
            'x-ref': '__root',
            ':id'() { return this.$id('wishlist-grid-root'); },
            'x-data'() {
                return {
                    __wishlistProductIds: [],
                    __isLoaded: false,
                    __isLoggedIn: false,
                    __showForNotLoggedIn: false, // Either to show or not wishlist icon for not logged in customers.
                    __executeToggle: () => {},

                    init() {
                        this.$csi('customer', 'wishlistProductIds', '__wishlistProductIds').then(() => {
                            this.__isLoaded = true;
                        });
                        this.$csi('customer', 'isLoggedIn', '__isLoggedIn').then(() => {
                            this.__isLoaded = true;
                        });
                        Alpine.effect(() => {
                            // Properties to observe.
                            [this.__wishlistProductIds];
                        });

                        const fx = (item) => {
                            // Don't allow toggling elements before loading widget.
                            if (!this.__isLoaded) {
                                return;
                            }
                            const productId = item.__productId;
                            // In case if state of the button does not yet reflect actual state in CS - update
                            // the state explicitly to match.
                            if (this.__wishlistProductIds.indexOf(productId) > -1) {
                                if (!item.__isAdded) {
                                    item.__isAdded = true;
                                    return;
                                }
                                fetch(addQueryToUrl(getControllerUrl('Wishlist-RemoveProduct'), { pid: productId }), {
                                    method: 'GET'
                                }).then((onFulfilled, onRejected) => { /* eslint-disable-line no-unused-vars */
                                    if (onFulfilled.ok) {
                                        onFulfilled.json().then(data => {
                                            if (!data.success) {
                                                messagesMgr.error(data.msg);
                                                item.__isAdded = this.__wishlistProductIds.indexOf(item.__productId) > -1;
                                            } else {
                                                if (data.msg || data.emptyWishlistMsg) {
                                                    messagesMgr.success(data.msg || data.emptyWishlistMsg);
                                                }
                                                item.__isAdded = false;
                                            }
                                        });
                                    } else {
                                        messagesMgr.error('Unable to remove item from wishlist');
                                        item.__isAdded = this.__wishlistProductIds.indexOf(item.__productId) > -1;
                                    }
                                });
                            } else {
                                // In case if state of the button does not yet reflect actual state in CS - update
                                // the state explicitly to match.
                                if (item.__isAdded) {
                                    item.__isAdded = false;
                                    return;
                                }
                                // Otherwise - proceed with regular action.
                                let addProductFormData = new FormData();
                                addProductFormData.append('pid', productId);
                                fetch(getControllerUrl('Wishlist-AddProduct'), {
                                    method: 'POST',
                                    body: addProductFormData
                                }).then((onFulfilled, onRejected) => { /* eslint-disable-line no-unused-vars */
                                    if (onFulfilled.ok) {
                                        onFulfilled.json().then(data => {
                                            if (!data.success) {
                                                messagesMgr.error(data.msg);
                                                item.__isAdded = false;
                                            } else {
                                                if (data.msg) {
                                                    messagesMgr.success(data.msg);
                                                }
                                                item.__isAdded = true;
                                            }
                                        });
                                    } else {
                                        messagesMgr.error('Unable to add item to wishlist');
                                        item.__isAdded = this.__wishlistProductIds.indexOf(item.__productId) > -1;
                                    }
                                    item.__stateInc++;
                                });
                            }
                        };

                        this.__executeToggle = _.throttle(fx.bind(this), 100);
                    },

                    __toggleItem(item) {
                        this.__executeToggle(item);
                    }
                };
            }
        });
    }

    /**
     * <div class="wishlist-wrapper hidden" data-wishlist-tile-item data-add-to-wish-wrapper data-add-to-wish-product-id="${product.id}">
     *     <a href="${dw.web.URLUtils.url('Wishlist-AddProduct')}" class="wishlist-icon"
     *     data-wishlist-product-id="${product.id}"
     *     data-add-to-wish-list data-wishlist-inlist="false"
     *     data-wishlist-addToWishlistUrl="${dw.web.URLUtils.url('Wishlist-AddProduct')}"
     *     data-wishlist-removeFromWishlistUrl="${dw.web.URLUtils.url('Wishlist-RemoveProduct')}">
     *         <i class="icon-nowishlist"></i>
     *     </a>
     *     <script>
     *         typeof setWishlistIconState === 'function' && setWishlistIconState(document.querySelectorAll('[data-add-to-wish-product-id="${product.id}"]'))
     *     </script>
     * </div>
     * ===
     * <div x-wishlist:toggleState x-data="{ __productId: '${product.id}' }">
     *    <i class="icon-nowishlist"></i>
     * </div>
     */

    function handleToggleState(el, Alpine) { /* eslint-disable-line no-shadow */
        Alpine.bind(el, {
            ':id'() { return this.$id('wishlist-toggle-state'); },
            'aria-haspopup': 'true',
            ':aria-labelledby'() { return this.$id('wishlist-toggle-state'); },
            ':aria-expanded'() { return this.__isAdded; },
            ':aria-controls'() { return this.__isAdded && this.$data.$id('wishlist-grid-root'); },
            ':class'() {
                return {
                    hidden: (!this.$data.__isLoggedIn && !this.$data.__showForNotLoggedIn) || !this.$data.__isLoaded || !this.__productId,
                    // Corresponded SCSS styles could be refactored to have only one class.
                    'wishlist-wrapper wishlist-icon': (this.$data.__isLoggedIn || this.$data.__showForNotLoggedIn) && this.$data.__isLoaded && this.__productId && this.__wishlistTileFlag,
                    inlist: (this.$data.__isLoggedIn || this.$data.__showForNotLoggedIn) && this.__isAdded
                };
            },
            'x-data'() {
                return {
                    __isAdded: false,
                    __productId: null,
                    __iconEl: null,
                    __stateInc: 0,
                    __wishlistTileFlag: true,

                    init() {
                        this.__generateElements();
                        this.__productId = this.$data.productId || Alpine.bound(el, 'productId', false);
                        this.__wishlistTileFlag = this.$data.wishlistTileFlag
                            ? this.$data.wishlistTileFlag
                            : Alpine.bound(el, 'wishlistTileFlag', true);

                        queueMicrotask(() => {
                            Alpine.effect(() => {
                                // Properties to observe.
                                [this.$data.__isLoaded, this.$data.__wishlistProductIds, this.$data.__stateInc];

                                this.$nextTick(() => {
                                    this.__isAdded = this.$data.__wishlistProductIds
                                        && this.$data.__wishlistProductIds.indexOf(this.__productId) > -1;

                                    if (this.__iconEl) {
                                        this.__iconEl.classList.toggle('icon-nowishlist', !this.__isAdded);
                                        this.__iconEl.classList.toggle('icon-wishlist', !!this.__isAdded);
                                    }
                                });
                            });
                        });
                    },

                    __generateElements() {
                        // Compatibility logic to preserve old styling and markup for wishlist icon.
                        let iconEl = document.createElement('i');
                        iconEl.classList.add('icon-nowishlist');
                        this.__iconEl = this.$el.appendChild(iconEl);
                    }
                };
            },

            '@keydown.throttle.500ms'(e) {
                if (['ArrowDown'].includes(e.key)) {
                    e.stopPropagation();
                    e.preventDefault();

                    this.$data.__toggleItem(this);
                }
            },
            '@keydown.space.stop.prevent.throttle.500ms'() { this.$data.__toggleItem(this); },
            '@keydown.enter.stop.prevent.throttle.500ms'() { this.$data.__toggleItem(this); },
            '@click.stop.prevent.throttle.500ms'() { this.$data.__toggleItem(this); }
        });
    }
}
