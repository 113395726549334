'use strict';

import { isFunctionalAllowed } from './consent/resolver';
import { execute as voyado } from './analytics/voyado';
import cs from 'shared/js/customerSections';

if (isFunctionalAllowed()) {
    voyado(cs);
}

