'use strict';

import CustomerSectionsChannel from './customerSectionsChannel';

/**
 * Simplified transaction method that just executed provided callback.
 *
 * @param callback
 * @return {Promise<*>}
 */
const executeTransaction = async (callback) => {
    let result;
    if (callback[Symbol.toStringTag] === 'AsyncFunction') {
        result = await callback();
    } else if (callback && typeof callback.then === 'function' && callback[Symbol.toStringTag] === 'Promise') { // NOSONAR
    } else {
        result = callback();
    }
    return result;
};

/**
 * Channel with support of Web Lock APIs.
 */
class CustomerSectionsChannelLockable extends CustomerSectionsChannel {
    transaction(flag, callback) {
        let self = this;
        let resolve;
        let reject;
        const lockPromise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
        const lockController = new AbortController();
        setTimeout(() => lockController.abort(), 5000);
        self.debug(`${flag}@LOCK Registering a new lock request ...`);
        let promises = [lockPromise];

        try {
            promises.push(navigator.locks.request(
                'WEB_API_LOCK_HANDLE_' + flag,
                { mode: 'exclusive', /* signal: lockController.signal */ ifAvailable: true },
                async (lock) => {
                    self.debug(`${flag}@LOCK Entering lock request, lock state = ${!!lock}`);
                    if (!lock) {
                        // The lock was not granted - get out fast.
                        reject();
                        self.debug(`${flag}@LOCK Lock is rejected. That potentially could be if browser did not yet prepare
                        a tab. Should be retried update in the case of not yet populated CS storage.`);

                        return;
                    }

                    self.debug(`${flag}@LOCK Executing transaction`);
                    await executeTransaction(callback).then(() => {
                        self.debug(`${flag}@LOCK Transaction is finished, resolving promise ...`);
                        resolve();
                        self.debug(`${flag}@LOCK ... promise is resolved`);
                    }).catch(() => reject());

                    self.debug(`${flag}@LOCK Returning lock promise`);
                    return lockPromise;
                }
            ));
        } catch (exception) {
            if (exception.name === 'AbortError') {
                self.debug(`${flag}@LOCK The lock request aborted before it could be granted`);
            }
        }

        self.debug(`${flag}@LOCK ... exiting lock request`);
        return Promise.all(promises);
    }
}

export default CustomerSectionsChannelLockable;
