'use strict';

let getSerializerPromiseCache;

export function normalizeKey(key) {
    // Cast the key to a string, as that's all we can set as a key.
    if (typeof key !== 'string') {
        console.warn(`${key} used as a key, but it is not a string.`);
        key = String(key);
    }

    return key;
}

export function getSerializerPromise(localForageInstance) {
    if (getSerializerPromiseCache) {
        return getSerializerPromiseCache;
    }
    if (!localForageInstance ||
        typeof localForageInstance.getSerializer !== 'function') {
        return Promise.reject(
            new Error(
                'localforage.getSerializer() was not available! ' +
                'localforage v1.4+ is required!',
            ),
        );
    }
    getSerializerPromiseCache = localForageInstance.getSerializer();
    return getSerializerPromiseCache;
}

export function getKeyPrefix(options, defaultConfig) {
    let keyPrefix = options.name + '_';

    if (options.storeName !== defaultConfig.storeName) {
        keyPrefix += options.storeName + '_';
    }
    return keyPrefix;
}

export function getCallback() {
    if (
        arguments.length &&
        typeof arguments[arguments.length - 1] === 'function'
    ) {
        return arguments[arguments.length - 1];
    }
}

export function executeCallback(
    promise,
    callback
) {
    if (callback) {
        promise.then(
            function (result) {
                callback(null, result);
            },
            function (error) {
                callback(error);
            },
        );
    }
    return promise;
}

export function isLocalStorageValid() {
    try {
        return (
            typeof localStorage !== 'undefined' &&
            'setItem' in localStorage &&
            // in IE8 typeof localStorage.setItem === 'object'
            !!localStorage.setItem
        );
    } catch (exception) {
        return false;
    }
}

export function isSessionStorageValid() {
    try {
        return (
            typeof sessionStorage !== 'undefined' &&
            'setItem' in sessionStorage &&
            // in IE8 typeof sessionStorage.setItem === 'object'
            !!sessionStorage.setItem
        );
    } catch (exception) {
        return false;
    }
}

export function isStorageValid(storage) {
    storage = storage || 'localStorage';
    if (storage === 'localStorage') {
        return isLocalStorageValid();
    }

    if (storage === 'sessionStorage') {
        return isSessionStorageValid();
    }

    return false;
}

// Check if session/localStorage throws when saving an item
export function checkIfStorageThrows(storage) {
    const storageTestKey = '_sync_data_support_test';

    try {
        storage.setItem(storageTestKey, 'true');
        storage.removeItem(storageTestKey);

        return false;
    } catch (exception) {
        return true;
    }
}

// Check if session/localStorage is usable and allows to save an item
// This method checks if session/localStorage is usable in Safari Private Browsing
// mode, or in any other case where the available quota for session/localStorage
// is 0 and there wasn't any saved items yet.
export function isStorageUsable(storage) {
    return !checkIfStorageThrows(storage) || storage.length > 0;
}
