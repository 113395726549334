'use strict';

import { getControllerPart, getControllerUrl, getControllerAlias } from 'shared/js/url';
import _ from 'shared/js/underscore';

let isLoggedIn = false;
const redirect = _.throttle(() => {
    const target = getControllerAlias('Login-Show');
    if (window.location.href.indexOf(target) === -1) {
        window.location.href = getControllerUrl(target);
    }
}, 8000);

const loginPaths = [
    getControllerAlias('Account-Overview'),
    getControllerAlias('Account-Achivements'), // Since there was a typo in Controller.
    getControllerAlias('Account-Achievements'),
    getControllerAlias('Account-Avatar'),
    getControllerAlias('Account-EditProfile'),
    getControllerAlias('Account-Leaderboard'),
    getControllerAlias('Account-Returns'),
    getControllerAlias('Account-Reviews'),
    getControllerAlias('Account-Show'),
    getControllerAlias('Account-EditPassword'),
    getControllerAlias('Wishlist-Search'),
    getControllerAlias('Wishlist-Show'),
    getControllerAlias('Wishlist-ShowOther'),
    getControllerAlias('Wishlist-BonusAndVoucher'),
    getControllerAlias('PaymentInstruments-List'),
    getControllerAlias('Address-List')
];

const getLoginStatus = (container) => {
    return typeof container === 'object' && container.hasOwnProperty('newValue')
        ? container.newValue
        : container;
};

const applyCheck = _.debounce((container) => {
    const isLoggedInActual = getLoginStatus(container);
    const currentController = getControllerPart(document.URL.toString());
    if (isLoggedInActual === isLoggedIn && !isLoggedIn
        && (_.some(loginPaths, (lp) => {
            return lp.toLowerCase() === currentController.toLowerCase();
        }) || /^Account-/.test(currentController) || /^Wishlist-/.test(currentController))) {
        redirect();
    }
    isLoggedIn = isLoggedInActual;
}, 6000);

export default {
    listenTo: 'customer:isLoggedIn',
    callback: (container) => {
        isLoggedIn = getLoginStatus(container);
        applyCheck(container);
    }
};
