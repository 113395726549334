'use strict';

import Provider from 'storefront/shared/integration/eventDispatcher/provider';
import cs from 'shared/js/customerSections';
import FeatureFlagProvider from 'theme/js/shared/featureFlagRegistrar';

// No events to listen to.
const LISTEN_ON = [''];
let merchantId = '';
let gmfLoaded = false;

const update = (merchantIdValue) => {
    if (gmfLoaded) {
        cs.getAwait('customer', 'gmfCustomerToken').then((gmfCustomerToken) => {
            window.gmf('update', {
                merchantId: merchantIdValue,
                customerToken: gmfCustomerToken || null,
                locale: window.locale
            });
        });
    }
};

const initialize = () => {
    cs.getAwait('configuration', 'gmfIsActive').then((isActive) => {
        if (isActive) {
            // Code snippet is copied from legacy GMF boot ISML, GMF loader version can be changed
            // or made configurable when needed.
            window.gmf = function () {
                (window._gmf = window._gmf || []).push(Object.values(arguments));
            };
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://cdn.gamifiera.com/static/gmf/loader.js';
            s.async = true;
            document.head.appendChild(s);

            cs.getAwait('customer', 'gmfCustomerToken').then((gmfCustomerToken) => {
                cs.getAwait('configuration', 'gmfMerchantId').then((gmfMerchantId) => {
                    merchantId = gmfMerchantId;
                    if (!gmfLoaded) {
                        window.gmf('init', {
                            merchantId: merchantId,
                            customerToken: gmfCustomerToken || null,
                            locale: window.locale
                        });
                        gmfLoaded = true;
                    } else {
                        window.gmf('update', {
                            merchantId: merchantId,
                            customerToken: gmfCustomerToken ? null : gmfCustomerToken,
                            locale: window.locale
                        });
                    }
                });
            });

            // Update observables are registered before CS storages are initialized.
            cs.observe('customer:isLoggedIn', () => {
                update(merchantId);
            });
            cs.observe('customer:gmfCustomerToken', () => {
                update(merchantId);
            });
        }
    });
};

class EventProvider extends Provider {
    // This even provider has to be created AFTER CS data is resolved.
    constructor() {
        super();

        initialize();
    }

    isApplicable(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return typeof container === 'object'
            && LISTEN_ON.indexOf(container.type) > -1
            && FeatureFlagProvider.get('gmf.integration.active', false);
    }

    generate(container, meta) {
        let result = [];

        if (!this.isApplicable(container, meta)) {
            return result;
        }

        return result;
    }
}

export default EventProvider;
