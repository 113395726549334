'use strict';

import { observeOverride } from './resolveOverrides';
import underscore from 'shared/js/underscore';
let _ = underscore;

const OBSERVABLE_LIST_FIELD = '__observables';
const SUBJECT_CHANNEL_OBSERVER_FLAG = '__channelNotificationFlag';
const OBSERVABLE_FIELD_DEFAULT = 'dbs';
const CHANNEL_OBSERVER_LOCK = 'crossTabUpdateNotification';

/**
 * This function's binding must be called AFTER data update.
 *
 * @param {string} [field='dbs']
 * @return {(function(*): void)|*}
 */
const notify = (field) => {
    field = field || OBSERVABLE_FIELD_DEFAULT;
    return function (sectionId) {
        let subject = this;
        subject.__observeField = field;

        if (subject.hasOwnProperty(field)
            && subject[field]
            && subject[field].hasOwnProperty(sectionId)
            && subject[field][sectionId]) {
            try {
                subject.channel.lock(CHANNEL_OBSERVER_LOCK, sectionId);
                _.delay(() => {}, 1);
            } finally {
                subject.channel.unlock(CHANNEL_OBSERVER_LOCK, undefined);
            }
        }
    };
};

const iterateObservables = (queries, { subject, field, sectionId, compareTs }) => {
    queries = _.uniq(queries);

    let pendingQueries = [];
    let promise = Promise.resolve(queries);

    if (queries.indexOf('*') > -1) {
        promise = subject[field][sectionId].keys();
    }
    if (queries.length) {
        promise.then((queriesList) => {
            subject[field][sectionId].getItems(queriesList).then((data) => {
                _.each(subject[field][sectionId][OBSERVABLE_LIST_FIELD], (observable) => {
                    const keyIndex = observable.__key;
                    // Skip processing of newer observables for older context.
                    if (observable.__ts > compareTs) {
                        pendingQueries.push(keyIndex);
                        return;
                    }
                    let container = {
                        key: keyIndex,
                        methodName: keyIndex === '*' ? 'setItems' : 'setItem',
                        oldValue: null,
                        newValue: keyIndex === '*' ? data : data[keyIndex],
                        valueChange: true,
                        success: true,
                        fail: false
                    };
                    _.each(observable.__subscribers, (subscriberInstance) => {
                        // Depending on the subscriber type - execute
                        // observable or regular callback.
                        if (subscriberInstance.next instanceof Function) {
                            subscriberInstance.next(container.newValue, container);
                        } else if (subscriberInstance instanceof Function) {
                            subscriberInstance(container.newValue, container);
                        }
                    });
                });
                if (pendingQueries.length) {
                    const newCompareTs = new Date().getTime();
                    iterateObservables(pendingQueries, { subject, field, sectionId, newCompareTs });
                }
            });
        });
    }
};

/**
 * Returns a function which registers new observable for a field (path).
 *
 *
 * @param {string} [field='dbs']
 * @param {Promise} readyCallback - promise only after resolving of which will be triggered value change notifications
 * @return {(function(string, string, function): void)}
 */
let observe = (field, readyCallback) => {
    field = field || OBSERVABLE_FIELD_DEFAULT;

    return function (sectionId, key, subscriber) { // NOSONAR
        let subject = this;
        key = typeof key === 'undefined' ? '' : key.toString();
        key = key.trim();

        if (!key || key === '') {
            key = '*';
        }

        // In case of specific field observation - check also overrides.
        if (key !== '*' && ['translations', 'assets', 'menu'].indexOf(sectionId) === -1) {
            observeOverride(sectionId, key, subscriber);
        }

        const reference = `${sectionId}:${key}`;
        readyCallback.then(() => {
            if (!subject[field].hasOwnProperty(sectionId)) {
                // Silently exit.
                return;
            }

            if (!subject[field][sectionId].hasOwnProperty(SUBJECT_CHANNEL_OBSERVER_FLAG)) {
                subject.channel.registerListenerCallback(
                    CHANNEL_OBSERVER_LOCK,

                    function (msg) {
                        // Snapshot the current TS.
                        // The observables which have TS later (newer) than current one will be skipped from
                        // the processing, to avoid out-dated context information (such context was created before
                        // the corresponded observable was registered).
                        const compareTs = new Date().getTime();

                        if ((msg.value === sectionId && msg.action === 'lock')
                            && subject[field][sectionId].hasOwnProperty(OBSERVABLE_LIST_FIELD)) {
                            // No need to filter out observables by TS value at that point.
                            let queries = _(subject[field][sectionId][OBSERVABLE_LIST_FIELD]).map((observable) => {
                                return observable.__key;
                            });
                            iterateObservables(queries, { subject, field, sectionId, compareTs });
                        }
                    }

                );
                subject[field][sectionId][SUBJECT_CHANNEL_OBSERVER_FLAG] = true;
            }

            let observable;
            if (subject[field][sectionId].hasOwnProperty(OBSERVABLE_LIST_FIELD)
                && subject[field][sectionId][OBSERVABLE_LIST_FIELD].hasOwnProperty(reference)) {
                // Observable already exists.
                observable = subject[field][sectionId][OBSERVABLE_LIST_FIELD][reference];
                observable.subscribe(subscriber);
                observable.__subscribers = Array.isArray(observable.__subscribers)
                    ? observable.__subscribers
                    : [];
                observable.__subscribers.push(subscriber);
                return;
            }

            if (['*', ''].indexOf(key) > -1) {
                // Observe all keys.
                observable = subject[field][sectionId].newObservable({
                    crossTabNotification: true,
                    setItem: true,
                    removeItem: true,
                    clear: false,
                    changeDetection: true
                });
            } else {
                observable = subject[field][sectionId].getItemObservable(key, {
                    crossTabNotification: true,
                    setItem: true,
                    removeItem: true,
                    clear: false,
                    changeDetection: true
                });
            }

            observable.__section = sectionId;
            observable.__key = key;
            // The timestamp indicating when the observable was registered.
            // Events (messages) triggered before that TS won't trigger notification
            // through observable registered after event's TS.
            // That intentional solution handles the case when event's context misses data
            // which has been resolved after the event's initial trigger.
            observable.__ts = new Date().getTime();

            observable.subscribe(subscriber);

            observable.__subscribers = Array.isArray(observable.__subscribers)
                ? observable.__subscribers
                : [];
            observable.__subscribers.push(subscriber);

            subject[field][sectionId][OBSERVABLE_LIST_FIELD] =
                subject[field][sectionId][OBSERVABLE_LIST_FIELD] || {};

            subject[field][sectionId][OBSERVABLE_LIST_FIELD][reference] = observable;
        });
    };
};

export { observe, notify };
