'use strict';

import cs from './../customerSections';

/* deprecated */

export default function async(sectionId) {
    return cs.readyPromise.then(() => {
        return cs.getAll(sectionId);
    });
}
