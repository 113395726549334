'use strict';

import { observeCookie } from 'shared/js/util/cookies-observer';
import { getCookieByName } from 'shared/js/util/cookies-operation';
export const COOKIE_NAME = 'public.config.presentation';
import _ from 'shared/js/underscore';

/**
 * This is a common module to read the storefront public configurations (sections) provided from
 * the backend.
 */

let data;

/**
 * Initialization function that reads target cookie and updates internal storage.
 * @return {Object}
 */
export function init() {
    const rawData = getCookieByName(COOKIE_NAME, true);
    data = {};
    try {
        data = typeof rawData === 'string' && rawData.length ? JSON.parse(rawData) : {};
        if (typeof data === 'string') {
            data = JSON.parse(data);
        } else if (typeof data !== 'object') {
            data = {};
        }
    } catch (exception) {
    // Invalid cookie value is provided, silently escape.
        console.log(exception); // eslint-disable-line no-console
    }
}

/**
 * Sections class.
 *
 * @constructor
 */
let CustomerMetaSections = function () {
    let self = this;
    this.callbacks = [];
    observeCookie(COOKIE_NAME, (prop, value) => { /* eslint-disable-line no-unused-vars */
        init();
        for (let index in self.callbacks) {
            self.callbacks[index](self, COOKIE_NAME, value, data);
        }
    }, null, false);
    // Do not execute callbacks on initialization.
    init();
};

/**
 * Get specific storefront section configuration value.
 *
 * @param {string} name - name of the stored section key.
 * @param {*} [defaults=undefined] - default value
 * @return {*}
 */
CustomerMetaSections.prototype.get = function (name, defaults) {
    return data.hasOwnProperty(name) ? data[name] : _.get(data, name.split('.'), defaults);
};

/**
 * Adds a meta-data cookie change callback listener.
 * @param {String} callbackId
 * @param {Function} callbackFunction
 */
CustomerMetaSections.prototype.addCallback = function (callbackId, callbackFunction) {
    if (callbackFunction instanceof Function) {
        this.callbacks[callbackId] = callbackFunction;
    }
};

/**
 * Removes meta-data cookie change callback listener.
 * @param {String} callbackId
 */
CustomerMetaSections.prototype.removeCallback = function (callbackId) {
    if (this.callbacks.hasOwnProperty(callbackId)) {
        delete this.callbacks[callbackId];
    }
};

export default new CustomerMetaSections();
