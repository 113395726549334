/* eslint-disable */

'use strict';

import _ from 'shared/js/underscore';
import cs from 'shared/js/customerSections';
import FeatureFlagProvider from 'shared/js/featureFlagRegistrar';
import { getControllerUrl } from 'shared/js/url';
import mediaQuery from 'improove/util/util';

// Declares next $magic:
// - $mq
// - $site
// - $cs
// - $csi
// - $url

// Declares next x-directive:
// - x-site

// -- $MAGIC --

export default function (Alpine) {
    Alpine.magic('mq', () => {
        return {
            getScreenSize: mediaQuery.getScreenSize.bind(mediaQuery),
            isMobile: mediaQuery.isMobile.bind(mediaQuery)
        }
    });

    Alpine.magic('_', () => _);

    Alpine.magic('site', () => {
        return (site) => {
            return site ? FeatureFlagProvider.isSite(site) : FeatureFlagProvider.getCurrentSite();
        }
    });

    Alpine.magic('cs', (el, { Alpine }) => {
        return async (sectionId, itemsRef, initialCallback, isArray) => {
            isArray = typeof isArray === 'undefined' ? true : !!isArray;
            const execute = (callback) => {
                return cs.readyPromise.then(() => {
                    return cs.getAll(sectionId).then(values => {
                        let $el = Alpine.$data(el);
                        // Use regular store with pre-initialized values.
                        // Alpine.store(`cs-${sectionId}`)[itemsRef].splice(0, Alpine.store(`cs-${sectionId}`)[itemsRef].length);
                        // Alpine.store(`cs-${sectionId}`)[itemsRef].push(...Object.values(values));
                        let container = {
                            sectionId, values, $el
                        };
                        let items = typeof itemsRef === 'function' ? itemsRef(container) : $el[itemsRef];
                        values = container.values;

                        if (!values || !(items instanceof Object)) {
                            return;
                        }

                        // Historical option B.
                        if (isArray) {
                            items.splice(0, items.length);
                            items.push(...Object.values(values));
                        } else {
                            // 3d argument - replace entries on N level (starting from 0).
                            // zero - it will overwrite all values for keys on 1st level with index = 0;
                            _.merge(items, values, 0);
                        }

                        if (typeof callback === 'function') {
                            $el.$nextTick(() => {
                                callback();
                            });
                        }

                        // Historical option A.
                        // let $data = Alpine.closestDataStack(el)[0];
                        // $data.updateItems(values);
                        // $data[itemsRef][0] = Object.values(values)[0];
                    });
                });
            }
            cs.observe(`${sectionId}:*`, {
                next: () => { // For args @see https://github.com/localForage/localForage-observable#api
                    execute();
                },
                reference: itemsRef,
                element: el
            });
            return execute(initialCallback);
        };
    });

    Alpine.magic('url', () => {
        return (path) => {
            return getControllerUrl(path);
        }
    });

    Alpine.magic('csi', (el, { Alpine }) => {
        /**
         * Applies a new value to a $data model of AlpineJs component.
         *
         * @param value
         * @param el
         * @param valueRef
         * @param subRef
         */
        const applyValue = (value, el, valueRef, subRef) => {
            if (typeof valueRef === 'string') {
                _.set(Alpine.$data(el), valueRef.split('.'), value);
            } else {
                _.set(valueRef, subRef.split('.'), value);
            }
        }

        return async (sectionId, path, valueRef, subRef) => {
            /**
             * Indicates that initial call for execute() has been already processed.
             * Avoids duplicated execution of the same value update.
             *
             * @type {boolean}
             */
            let initializedFlag = false;
            const execute = (container) => {
                if (container && container.value) {
                    return Promise.resolve(container.value).then(value => {
                        applyValue(value, el, valueRef, subRef);
                        initializedFlag = true;
                    });
                } else {
                    return cs.readyPromise.then(() => {
                        return cs.getAwait(sectionId, path).then(value => {
                            applyValue(value, el, valueRef, subRef);
                            initializedFlag = true;
                        })
                    });
                }
            };

            cs.observe(`${sectionId}:${path}`, {
                next: (value) => { // For args @see https://github.com/localForage/localForage-observable#api
                    if (initializedFlag) {
                        execute({ value });
                    }
                },
                reference: path,
                element: el
            });
            return execute();
        };
    });

    Alpine.magic('he', () => {
        return (nodeClass) => {
            let elements = document.getElementsByClassName(nodeClass);
            _.each(elements, element => {
                if (element && element.style) {
                    element.style.display = 'none';
                }
            });
        };
    });

    // -- DIRECTIVES --

    /**
     * @example x-site:checkout.klarna.instantshopping.active - will show node only
     *   when site in featureFlag registry has klarna instant shopping flag being turned ON.
     * @example x-site.BS.GG:show - will show node only on BS and GG sites.
     * @example x-site.GG:show="isLoggedIn"
     *  - will show node only on GG site when customer is logged in.
     */
    Alpine.directive('site', (el, { value, modifiers, expression }, { evaluateLater, effect }) => {
        // @see https://alpinejs.dev/advanced/extending#method-signature
        // For further extending, currently only show-hide logic is present.
        let showFlag = true;
        if (value === 'show' || value === 'hide') {
            showFlag = value === 'show'
                ? FeatureFlagProvider.isSite(modifiers)
                : !FeatureFlagProvider.isSite(modifiers);
        } else {
            if (modifiers) {
                showFlag = FeatureFlagProvider.isSite(modifiers);
            }
            showFlag = showFlag && FeatureFlagProvider.get(value);
        }
        if (!showFlag) {
            el.style.display = 'none';
        } else if (expression) {
            let evaluateLaterInstance = evaluateLater(expression);
            effect(() => evaluateLaterInstance(evaluated => {
                el.style.display = !evaluated ? 'none' : 'inherit';
            }));
        } else {
            // Show flag is true.
            el.style.display = 'inherit';
        }
    });
}
