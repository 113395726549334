'use strict';

import _ from 'shared/js/underscore';
import { getLogger } from 'shared/js/dev-mode';

const logger = getLogger();

const canBeUsed = () => {
    return typeof BroadcastChannel === 'function';
};

class CustomerSectionsChannel {
    /**
     * @type {string}
     */
    channelId;
    /**
     * @type {BroadcastChannel}
     */
    channelInstance;

    /**
     * @type {Object}
     */
    owner;
    /**
     * @type {{Object}}
     */
    callbacksListener;

    /**
     * @type {Object}
     */
    callbacksTrigger;

    /**
     * @type {Object}
     */
    shared;

    /**
     * @type {boolean}
     */
    active;

    /**
     * @param {string} [channelId='csc']
     */
    constructor(channelId) {
        this.channelId = channelId || 'csc';
        this.registered = false;
        this.channelInstance = null;
        this.owner = {};
        this.callbacksListener = {};
        this.callbacksTrigger = {};
        this.shared = {};
        this.active = canBeUsed();
        // In case of non-supported browsers (ex. IE11) do not activate functionality.
        // @see https://caniuse.com/broadcastchannel
        if (this.active) {
            this.channelInstance = new BroadcastChannel(this.channelId);
            this.channelInstance.onmessage = this.handleMessage.bind(this);
            this.channelInstance.postMessage({
                action: 'register',
                flag: 'request'
            });
        }
    }

    handleMessage(event) {
        const msg = event.data;
        if (typeof msg === 'object') {
            let flagCallbacks = [];
            const flag = msg.flag;
            switch (msg.action) { // eslint-disable-line no-case-declarations
            case 'lock': // eslint-disable-line no-case-declarations
                if (this.owner[flag]) {
                    return;
                }
                this.owner[flag] = 0;
                this.shared[flag] = msg.value;
                flagCallbacks = this.callbacksListener[flag] || [];
                flagCallbacks.forEach((callback) => callback(msg));
                break;
            case 'unlock': // eslint-disable-line no-case-declarations
                delete this.owner[flag];
                this.shared[flag] = msg.value;
                flagCallbacks = this.callbacksListener[flag] || [];
                flagCallbacks.forEach((callback) => callback(msg));
                break;
            case 'shared': // eslint-disable-line no-case-declarations
                this.shared[flag] = msg.value;
                break;
            case 'notification':
                flagCallbacks = this.callbacksListener[flag] || [];
                flagCallbacks.forEach((callback) => callback(msg));
                break;
            case 'register': // eslint-disable-line no-case-declarations
                // Next fragment handles response to a requester tab with information
                // that the current channel listener does already have.
                if (flag === 'request' && this.registered) {
                    let registerMgs = {
                        action: 'register',
                        flag: 'response',
                        value: this.shared
                    };
                    // Broadcast register-response message that contains shared data.
                    this.channelInstance.postMessage(registerMgs);
                } else if (flag === 'response' && !this.registered) {
                    // The next part handles responses for those listeners that awaits
                    // shared data from other registered tabs.
                    this.register(msg.value);
                }
                break;
            default:
                break;
            }
        }
    }

    register(data) {
        if (!this.registered) {
            this.registered = true;
            if (typeof data === 'object') {
                this.shared = _.merge(this.shared, data);
            }
        }
    }

    lock(flag, value, delay) {
        delay = delay || 10; // Defaults 10 ms
        if (!this.active) {
            return;
        }
        const msg = {
            action: 'lock',
            flag: flag,
            value: typeof value === 'undefined' ? 1 : value
        };
        this.shared[flag] = msg.value;
        this.owner[flag] = 1;
        this.channelInstance.postMessage(msg);
        const flagCallbacks = this.callbacksTrigger[flag] || [];
        _.delay(() => {
            flagCallbacks.forEach((callback) => callback(msg));
        }, delay);
    }

    unlock(flag, value) {
        if (!this.active) {
            return;
        }
        if (!this.owner[flag]) {
            return; // Do not allow unlocking by not owner of the lock.
        }
        const msg = {
            action: 'unlock',
            flag: flag,
            value: typeof value === 'undefined' ? 0 : value
        };
        delete this.owner[flag];
        this.shared[flag] = msg.value;
        this.channelInstance.postMessage(msg);
        const flagCallbacks = this.callbacksTrigger[flag] || [];
        flagCallbacks.forEach((callback) => callback(msg));
    }

    registerListenerCallback(flag, callback) {
        Array.isArray(this.callbacksListener[flag])
            ? this.callbacksListener[flag].push(callback)
            : this.callbacksListener[flag] = [callback];
    }

    registerTriggerCallback(flag, callback) {
        Array.isArray(this.callbacksTrigger[flag])
            ? this.callbacksTrigger[flag].push(callback)
            : this.callbacksTrigger[flag] = [callback];
    }

    get(flag, defaults) {
        return this.shared.hasOwnProperty(flag) ? this.shared[flag] : defaults;
    }

    set(flag, value) {
        if (this.owner.hasOwnProperty('flag') && !this.owner[flag]) {
            // Only flag owner tab can set values.
            return;
        }
        this.shared[flag] = value;
        if (!this.active) {
            return;
        }
        this.channelInstance.postMessage({
            action: 'shared',
            flag: flag,
            value: value
        });
    }

    notify(flag, value) {
        const msg = {
            action: 'notification',
            flag: flag,
            value: typeof value === 'undefined' ? 0 : value
        };
        this.channelInstance.postMessage(msg);
    }

    async transaction(flag, callback, elseCallback, value0, value1) {
        let self = this;
        let result;
        value0 = typeof value0 === 'undefined' ? false : value0;
        value1 = typeof value1 === 'undefined' ? true : value1;

        if (!this.get(flag, value0)) {
            try {
                this.lock(flag, value0);
                if (callback[Symbol.toStringTag] === 'AsyncFunction') {
                    result = await callback();
                    this.unlock(flag, value1);
                } else if (callback && typeof callback.then === 'function' && callback[Symbol.toStringTag] === 'Promise') {
                    result = callback.then(() => self.unlock(flag, value1));
                } else {
                    result = callback();
                    this.unlock(flag, value1);
                }
            } catch (exception) {
                this.unlock(flag, value0);
                throw exception;
            }
        } else if (elseCallback && callback[Symbol.toStringTag] === 'AsyncFunction') {
            result = await elseCallback();
        } else if (elseCallback instanceof Function) {
            result = elseCallback();
        }

        return result;
    }

    debug(stmt) {
        // For easier navigations - all data updates & booting actions starts with message prefix:
        // "CSC [verb] ..."
        logger.debug(`[${(new Date().toLocaleString())} @ CSC:${this.channelId}] ${stmt}`);
    }
}

export default CustomerSectionsChannel;
