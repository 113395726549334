'use strict';

import loginTrigger from './triggers/login';
import logoutTrigger from './triggers/logout';

let scenarios = [
    loginTrigger,
    logoutTrigger
];

export default scenarios;
