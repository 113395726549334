'use strict';

const forceResolveTokenFlag = false;
import CustomerMetaSections, { init } from './customerSections/customerMetaSections';

let { name, value } = CustomerMetaSections.get('csrfToken', {
    name: '',
    value: ''
});

CustomerMetaSections.addCallback('update_csrf_token', (cms, cookieName, cookieValue, data) => {
    if (data && data.csrfToken) {
        name = data.csrfToken.name;
        value = data.csrfToken.value;
    }
});

export function getCsrfName() {
    if (forceResolveTokenFlag) {
        init();
        return CustomerMetaSections.get('csrfToken', {}).name || name;
    }
    return name;
}

export function getCsrfValue() {
    if (forceResolveTokenFlag) {
        init();
        return CustomerMetaSections.get('csrfToken', {}).value || value;
    }
    return value;
}
