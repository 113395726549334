// noinspection DuplicatedCode

'use strict';

import _ from 'shared/js/underscore';
import { rules } from './voyado/pageEvents';
import { getLogger } from 'shared/js/dev-mode';

const logger = getLogger();

export const VOYADO_EVENT_MONITOR_CART = 'Voyado-monitor-cart';
export const VOYADO_EVENT_MONITOR_EMPTY_CART = 'Voyado-monitor-empty-cart';

const getVoyadoLocale = (sourceLocale) => {
    let result = 'sv-SE';
    sourceLocale = sourceLocale || window.locale;
    if (sourceLocale === 'no_NO') {
        result = sourceLocale.replace('no_NO', 'nb-NO');
    } else if (sourceLocale) {
        result = sourceLocale.replace('_', '-');
    }
    return result;
};

const track = (cs) => {
    return cs.readyPromise.then(() => {
        return cs.getAwait('customer', 'voyadoContactId').then(voyadoContactId => {
            // The next logic reflects logic implemented in SFCC-394
            // (file: app_hsng/cartridge/templates/default/voyadotracking/init.isml)
            let queryParams = new URLSearchParams(window.location.search);
            const vtid = queryParams.has('vtid') ? queryParams.get('vtid') : null;
            let cId = vtid || voyadoContactId;
            // Replicates the same way of cId "default" value as in legacy Voyado integration.
            if (cId === 'null' || cId === null) {
                cId = '';
            }

            if (cId) {
                window.va('setContactId', cId);
            }
        });
    });
};

const trackCart = (cs, uuid, isEmpty, basketItems, basketLocale) => {
    return cs.readyPromise.then(() => {
        let payload = {
            locale: getVoyadoLocale(basketLocale),
            cartRef: uuid
        };

        if (!isEmpty) {
            if (basketItems.length == 0) {
                isEmpty = true;
            } else {
                payload.items = [];
                basketItems.forEach(function (item) {
                    payload.items.push({
                        itemId: item.id,
                        quantity: item.quantity
                    });
                });
            }
        } else {
            payload.cartRef = $('.confirmation-summary').find('.order-number')[0].getAttribute('data-basket-uuid');
        }

        if (payload.cartRef) {
            window.va(isEmpty ? 'emptyCart' : 'cart', payload);
        }
    });
};

const initialize = (cs) => {
    track(cs);

    document.addEventListener(
        VOYADO_EVENT_MONITOR_CART,
        function (event) {
            trackCart(cs, event.detail.uuid, event.detail.empty, event.detail.items, event.detail.locale);
        },
        false
    );

    document.addEventListener(
        VOYADO_EVENT_MONITOR_EMPTY_CART,
        function (event) {
            trackCart(cs, null, event.detail.empty);
        },
        false
    );


    // Process rules.
    rules.forEach((rule) => {
        if (!rule || !Array.isArray(rule.actionIds)) {
            return;
        }
        try {
            if (window.actionId && rule.actionIds.indexOf(window.actionId) > -1) {
                rule.callback();
            }
        } catch (exception) {
            logger.error(exception);
        }
    });
};

export const execute = (cs) => {
    // Don't perform tracking on basket UUID *runtime* changes, do it only on new pages.
    // To keep consistency in metrics with older "static" approach.
    // cs.observe(`basket:uuid`, {
    //     next: () => { // For args @see https://github.com/localForage/localForage-observable#api
    //         track(cs);
    //         trackCart(cs);
    //     }
    // });
    return cs.readyPromise.then(() => {
        let voyadoEnabled = $('.data-holder').data('voyado-analytics-enabled');

        if (voyadoEnabled) {
            _.delay(() => {
                // Wait until Voyado script is loaded.
                if (typeof window.va !== 'function') {
                    execute(cs);
                } else {
                    initialize(cs);
                }
            }, 50);
        }
    });
};

export default {
    execute,
    VOYADO_EVENT_MONITOR_CART,
    VOYADO_EVENT_MONITOR_EMPTY_CART
};
