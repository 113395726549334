'use strict';

import _ from 'shared/js/underscore';
import { getControllerPart, getControllerUrl, getControllerAlias } from 'shared/js/url';

const loginPaths = ['login', getControllerAlias('Login-Show')];
let isLoggedIn = false;

const getLoginStatus = (container) => {
    return typeof container === 'object' && container.hasOwnProperty('newValue')
        ? container.newValue
        : container;
};

const redirect = _.throttle(() => {
    const target = getControllerAlias('Account-Overview');
    if (window.location.href.indexOf(target) === -1) {
        window.location.href = getControllerUrl(target);
    }
}, 8000);

const applyCheck = _.debounce((container) => {
    const isLoggedInActual = getLoginStatus(container);
    const currentController = getControllerPart(document.URL.toString());
    if (isLoggedIn === isLoggedInActual && isLoggedIn && _.some(loginPaths, (lp) => {
        return lp.toLowerCase() === currentController.toLowerCase();
    })) {
        redirect();
    }
    isLoggedIn = isLoggedInActual;
}, 6000);

export default {
    listenTo: 'customer:isLoggedIn',
    callback: (container) => {
        isLoggedIn = getLoginStatus(container);
        applyCheck(container);
    }
};
